let abTestReload = false;

$(() => {
  /**
   * Tableau dans lequel il faut paramétrer les AB tests en cours sur les pages cachées (HP, FP Overview, FP Activities ...)
   *
   * Example : {
   *  code: 'ABTEST_ACCOM_BLOCK_FP',
   *  univers: ['cpe'], // ['cpe', 'sp']
   *  marketList: ['all'], // ['fr', 'be', 'de']
   *  trackingVarName: 'ABTestAccomBlockFp',
   *  globalVarName: 'ABTEST_ACCOM_BLOCK_FP',
   *  device: 'mobile' // Not mandatory
   * }
   *
   */

  const campaignlist = [{
    code: 'ABTEST_COMPARATOR',
    univers: ['cpe'],
    marketList: ['all'],
    trackingVarName: 'ABTestComparator',
    globalVarName: 'ABTEST_COMPARATOR'
  }, {
    code: 'ABCTEST_ACCOS_BLOCK',
    univers: ['cpe'],
    marketList: ['all'],
    trackingVarName: 'ABTestAccosBlock',
    globalVarName: 'ABCTEST_ACCOS_BLOCK'
  }, {
    code: 'ABTEST_FBA_REGISTER',
    univers: ['fba'],
    marketList: ['all'],
    trackingVarName: 'ABTestFbaRegister',
    globalVarName: 'ABTEST_FBA_REGISTER'
  }, {
    code: 'ABTEST_WEMAP',
    univers: ['cpe'],
    marketList: ['all'],
    trackingVarName: 'ABWemap',
    globalVarName: 'ABTEST_WEMAP'
  }];

  //using promises to wait for only one reload
  $.when(
    abTest_chained(campaignlist)
  ).done(() => {
    if (abTestReload) {
      window.location.reload();
    } else {
      if (typeof didomi !== typeof undefined) {
        document.dispatchEvent(didomi);
      }
    }
  });
});

//FONCTION POUR LANCER TOUS LES ab_test
const abTest_chained = function (campaignlist) {
  const nbCampaign = campaignlist.length;
  const deferred = $.Deferred();

  if (nbCampaign) {
    let countCampaign = 0;
    let trackABTest = false;

    const trackingData = {
      //tracking for store abtest version
      gaCategory: PAGE_SCRIPTS.page_template,
      gaAction: 'AB test cookie value',
      gaLabel: 'cookie ready',
      gaValue: 1,
      event: 'ABevent'
    };

    $(campaignlist).each((_index, campaign) => {
      const campaignCode = campaign.code;
      const campaignMarketlist = campaign.marketList;
      const campaignUniversList = campaign.univers;
      const trackingVarName = campaign.trackingVarName;
      const globalVarName = campaign.globalVarName;
      const controller = campaign.controller;
      const device = campaign.device;

      if ((typeof controller === typeof undefined || controller === GLOBAL_SCRIPTS.CONTROLLER) && (typeof device === typeof undefined || (device === 'mobile' && DEVICE.isMobile))) {
        let getJson = false;
        let reloadDateChange = false;

        //test to know if we've got to set server side cookie
        const abtestCookie = cookieManagement.getCookie(`ABTest_${campaignCode}_${GLOBAL_SCRIPTS.UNIVERS.toUpperCase()}_${GLOBAL_SCRIPTS.MARKET_CODE.toUpperCase()}`);
        const isInMarketList = campaignMarketlist.indexOf(GLOBAL_SCRIPTS.MARKET_CODE.toLowerCase()) > -1 || campaignMarketlist.indexOf('all') > -1;
        const isInUniversList = campaignUniversList.indexOf(GLOBAL_SCRIPTS.UNIVERS.toLowerCase()) > -1 || campaignUniversList.indexOf('all') > -1;

        if (isInMarketList && isInUniversList) {
          if (abtestCookie === null) {
            getJson = true;
          } else {
            let contentCookie = abtestCookie.split('@');
            if (typeof contentCookie[2] !== typeof undefined &&
              typeof GLOBAL_SCRIPTS.ABTEST_LIST !== typeof undefined &&
              typeof GLOBAL_SCRIPTS.ABTEST_LIST[campaignCode] !== typeof undefined &&
              typeof GLOBAL_SCRIPTS.ABTEST_LIST[campaignCode].date !== typeof undefined &&
              GLOBAL_SCRIPTS.ABTEST_LIST[campaignCode].date !== contentCookie[2]
            ) {
              getJson = true;
              reloadDateChange = true;
            }
          }
        }

        if (getJson) {
          $.ajax({
            url: GLOBAL_SCRIPTS.URL_ABTEST,
            dataType: 'json',
            data: {
              campaign: campaignCode,
              market: GLOBAL_SCRIPTS.MARKET_CODE,
              univers: GLOBAL_SCRIPTS.UNIVERS
            }
          }).done(callback => {
            let abTest = typeof callback.results.scenario === typeof undefined ? 'A' : callback.results.scenario;

            if (abTest !== 'A' || reloadDateChange) {
              abTestReload = true;
            }

            countCampaign++;

            // store scenario for tracking
            if (typeof trackingVarName !== typeof undefined && trackingVarName !== '') {
              GLOBAL_SCRIPTS[globalVarName] = abTest;
              trackingData[trackingVarName] = abTest;
              trackABTest = true;
            }

            if (countCampaign === nbCampaign) {
              deferred.resolve();
            }
          }).fail(() => {
            deferred.resolve();
          });
        } else {
          // on affecte la valeur de l'ABTest en javascript dans le global_script à cause du cache akamai
          const cookieValue = abtestCookie !== null && typeof abtestCookie.split('@')[1] !== typeof undefined ? abtestCookie.split('@')[1] : 'A';

          GLOBAL_SCRIPTS[globalVarName] = cookieValue;
          // store scenario for tracking
          if (typeof trackingVarName !== typeof undefined && trackingVarName !== '') {
            trackingData[trackingVarName] = cookieValue;
            trackABTest = true;
          }

          countCampaign++;

          if (countCampaign === nbCampaign) {
            deferred.resolve();
          }
        }
      } else {
        countCampaign++;

        if (countCampaign === nbCampaign) {
          deferred.resolve();
        }
      }
    });
  } else {
    deferred.resolve();
  }

  return deferred.promise();
};